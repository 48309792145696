const BASE_URL = "https://bharatkrushiseva.in/api_delivery/api_procurment/";
const ApiConfig = {
  // BASE_URL:BASE_URL,
  Get_FTE_Data:BASE_URL+"getFTEData",
  Get_User_Communication_Alert:BASE_URL+"getUserCommunicationAlert",
  Get_Color_Distribution_Field_Data:BASE_URL+"getColorDistributionFieldData",
  Get_Ucs_Video_Tour_And_FteMedia:BASE_URL+"getUcsVideoTourAndFteMedia",
  Get_Advisor_Connect_Comment_History:BASE_URL+"getAdvisorConnectCommentHistory",
  Get_Ucs_Alert_Data:BASE_URL+"getUcsAlertData",
  Get_Crop_Nutrition_Helth_Benefits:BASE_URL+"getCropNutritionHelthBenefits",
  Add_Talk_To_Us_Data:BASE_URL+"addTalkToUsData",
  Get_Farmer_Impact_Summary:BASE_URL+"getFarmerImpactSummary",
  Get_Farm_Input:BASE_URL+"getFarmInput",
  Get_Farmer_Review_Rating:BASE_URL+"getFarmerReviewRating",
  Add_Review_And_Rating:BASE_URL+"addReviewAndRating",
  Add_User_Like_To_Farmer:BASE_URL+"addUserLikeToFarmer",
  Get_Freshness_Suger_Age:BASE_URL+"getFreshnessSugerAge",
  Get_Ucs_Calender_Data_By_FteId:BASE_URL+"getUcsCalenderDataByFteId",

  
  
  
  
  
};
export default ApiConfig;