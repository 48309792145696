import React from "react";
import instagram from "../assets/images/ic_instagram.png";
import ic_linkedin from "../assets/images/ic_linkedin.svg";
import ic_fb from "../assets/images/ic_fb.svg";
import ic_youtube from "../assets/images/ic_youtube.svg";
import ic_twitter from "../assets/images/ic_twitter.svg";
import copyright from "../assets/images/icons8-copyright-32.png";
import { Link } from "react-router-dom";
import logo from "../assets/images/bks_logo.jpg";

const Footer = () => {
  

  return (
    <div className="override-container">
      <footer>
        <div className="footer-logo">
          <img src={logo} alt="Logo" />
        </div>
        
        <div className="footer-text">
          <a href="https://bharatkrushiseva.com/terms_condition.html" target="_blank" rel="noopener noreferrer">
            Terms & Conditions | Privacy Policy
          </a>
        </div>

        <div className="footer-copyright">
          <p>© 2021 Bharat Krushi Seva Pvt. Ltd. All Rights Reserved.</p>
        </div>
      </footer>

   </div>
  );
};

export default Footer;
