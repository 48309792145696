import React, { useState, useEffect } from "react";
import { Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import logo from "../assets/images/bks_logo.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';
import textback from "../assets/images/backimagetext.png";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Handle resize events to determine if mobile or desktop view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992); // Example breakpoint for mobile
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
        <header class="header">
          <div class="logo">
            <a href="https://bharatkrushiseva.com/" target="_blank" rel="noopener noreferrer">
              <img src={logo} alt="Logo" style={{ cursor: "pointer" }} />
            </a>
          </div>
          
        </header>
        <div >
          <h5 class="fw-bold setTitle1121">The Story Of Your Pomegranate</h5>
          <img className="setImagesStrok" src={textback} alt="Logo" style={{ cursor: "pointer"  }} />
        </div>
    </div>
  );
};

export default Navbar;
