import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./assets/style/main.scss";


import Home from "./pages/Home";

import { useContext, useEffect } from "react";
import ReactGA from 'react-ga';


function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const navigate = useNavigate();
  const {pathname}=useLocation();
  
  useEffect(() => {
    window.scrollTo(0,0)
  },[pathname])

  const currentRoute = useLocation().pathname;



  useEffect(() => {
      if(currentRoute === "/" )
          navigate("/home");
      else navigate(currentRoute);
  });


  return (
    <div className="App container">
      <Routes>
          <Route path="/" element={<Home />}  />
          <Route path="home/:id" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
